// export const COUPON_CODE_TYPES = [
//   { code: '30DayFree', label: '30 Days Free (WMS)' },
//   { code: 'Save25', label: '$25 Off' },
//   { code: 'Save50', label: '$50 Off' },
//   { code: 'Save75', label: '$75 Off' },
//   { code: 'Save100', label: '$100 Off' },
//   { code: 'Save20%', label: '20% Off' },
// ];

export const COUPON_CODE_TYPES = [
  { code: 'free_trial', label: 'Free Trial', val_label: '# Free Days' },
  { code: 'discount', label: 'Discount', val_label: 'Amount Off' },
  { code: 'percent_off', label: 'Percent Off', val_label: 'Percent Off' },
];

export const CouponCodeOfferDesc = (code) => {
  if (!code) {
    return '';
  }
  if (code.type === 'free_trial') {
    return `${code.value} Days Free`;
  } else if (code.type === 'discount') {
    return `$${code.value} Discount`;
  } else if (code.type === 'percent_off') {
    return `${code.value}% off`;
  }
};

export const ERROR_MESSAGES = {
  Default: 'An unexpected error has occurred. Please try again.',
  TrialExpired: 'Your trial has expired.',
  SubscriptionExpired: 'Your subscription has expired.',
};

export const PRODUCTS = [
  {
    id: 'wms',
    name: 'Well Managed Schools',
    isActive: true,
    homePage: '/well-managed-schools',
    trans_desc: 'Well-Managed Schools Self-Paced Online Training for Educators',
    d365_product_name: 'LIFT SUB-PF-2',
  },
  {
    id: 'ai',
    name: 'Administrative Intervention',
    isActive: true,
    homePage: '/administrative-intervention',
    trans_desc: 'Administrative Intervention Self-Paced Online Training',
    d365_product_name: 'AI DIGITAL SUB',
  },
];

export const HOME_PAGE = PRODUCTS.find((product) => product.id === 'wms').homePage;

export const SUBSCRIPTION_ENTITY_TYPES = {
  user: 'user',
  school: 'school',
  district: 'district',
};

export const SUBSCRIPTION_TYPES = {
  FreeTrial: 'FreeTrial',
  Free: 'Free',
  Individual: 'Individual',
};

export const SUBSCRIPTION_PRICES = {
  FreeTrial: 0,
  Free: 0,
  Individual: 89,
};

export const FEATURE_SWITCH_TYPES = [
  { switch_name: 'Basic', label: 'Basic (On or Off)' },
  { switch_name: 'User List', label: 'User List (Applies CSV list of users)' },
  { switch_name: 'Percentage', label: 'Percentage (On x percent of time)' },
];

export const COMMON_ROUTES = {
  PricingPage: '/plan-options',
  SignIn: '/signin',
  LessonPlan: '/premium-resources/lesson-plan',
};

export const LEAD_TITLES = [
  { title: 'Assistant or Vice Principal', decision_maker: 'Yes', school_required: true, crm_val: 924790000 },
  { title: 'District Administrator (Superintendent)', decision_maker: 'Yes', school_required: false, crm_val: 924790001 },
  { title: 'Family Engagement Services Director', decision_maker: 'Yes', school_required: false, crm_val: 924790002 },
  { title: 'Principal (Dean, Head of School, Executive Director)', decision_maker: 'Yes', school_required: true, crm_val: 924790003 },
  {
    title: 'School Counselor or Specialist (psychologist, social worker, speech & language)',
    decision_maker: 'No',
    school_required: true,
    crm_val: 924790004,
  },
  {
    title: 'School Support Staff (administrative, secretary, office staff, nurse, librarian)',
    decision_maker: 'No',
    school_required: true,
    crm_val: 924790005,
  },
  { title: 'Special Education/Student Support Services Director', decision_maker: 'Yes', school_required: false, crm_val: 924790006 },
  { title: 'Teacher (Teaching Assistant, Paraprofessional)', decision_maker: 'No', school_required: true, crm_val: 924790007 },
  { title: 'Other', decision_maker: 'No', school_required: false, crm_val: 924790008 },
];

export const D365_CONSTANTS = {
  INSTITUTION_TYPE: {
    SCHOOL: 924790000,
    DISTRICT: 924790001,
    OTHER: 924790002,
  },
  ACCOUNT_TYPE: {
    INDIVIDUAL: 924790001,
    INSTITUTION: 924790000,
  },
  LICENSE_TYPE: {
    FREE_TRIAL: 924790000,
    FOR_FEE: 924790001,
  },
  LICENSE: {
    INITIAL: 924790000,
    RENEWAL: 924790001,
  },
  WEBSITE_USER_TYPE: {
    LEARNER_INDIVIDUAL: 924790000,
    LEARNER_INSTITUTION: 924790001,
    ADMINISTRATOR_INSTITUTION: 924790002,
    OTHER: 924790003,
  },
};

export const OTHER_DISTRICT = {
  name: 'Other',
  id: '6d896e9f-c204-4caf-8dce-7542f401894b',
};

export const OTHER_SCHOOL = {
  name: 'Other',
  id: 'a63b823f-a6a1-453b-a144-ba9f440388f9',
};

export const TZ_MAP = new Map([
  ['CDT', 'America/Chicago'],
  ['CST', 'America/Chicago'],
  ['EST', 'America/New_York'],
  ['EDT', 'America/New_York'],
  ['MDT', 'America/Denver'],
  ['MST', 'America/Denver'],
  ['PDT', 'America/Los_Angeles'],
  ['PST', 'America/Los_Angeles'],
]);

export const LESSON_PLAN_DIFFICULTY_LEVEL = [
  { name: 'Basic', value: 'basic' },
  { name: 'Intermediate', value: 'intermediate' },
  { name: 'Advanced', value: 'advanced' },
  { name: 'Complex', value: 'complex' },
];

export const OBSERVATION_PERMISSION_LIST = ['Admin', 'None', 'Observer'];

export const DEPARTMENT_LIST = ['Central Florida', 'North Florida', 'South Florida', 'Central Nebraska', 'East Nebraska', 'External', 'National Training', 'Nevada', 'New England'].sort();

export const GRADE_LIST = [
  { name: 'Pre-school', value: 'Pre-school' },
  { name: 'Kindergarten', value: 'Kindergarten' },
  { name: '1st Grade', value: '1st Grade' },
  { name: '2nd Grade', value: '2nd Grade' },
  { name: '3rd Grade', value: '3rd Grade' },
  { name: '4th Grade', value: '4th Grade' },
  { name: '5th Grade', value: '5th Grade' },
  { name: '6th Grade', value: '6th Grade' },
  { name: '7th Grade', value: '7th Grade' },
  { name: '8th Grade', value: '8th Grade' },
  { name: '9th Grade', value: '9th Grade' },
  { name: '10th Grade', value: '10th Grade' },
  { name: '11th Grade', value: '11th Grade' },
  { name: '12th Grade', value: '12th Grade' },
];

export const OBS_SUBJECT_LIST = [
  { name: 'Art', value: 'Art' },
  { name: 'Business', value: 'Business' },
  { name: 'English', value: 'English' },
  { name: 'ESL/ELL', value: 'ESL/ELL' },
  { name: 'History', value: 'History' },
  { name: 'Math', value: 'Math' },
  { name: 'Music', value: 'Music' },
  { name: 'Performing Arts', value: 'Performing Arts' },
  { name: 'Physical Education', value: 'Physical Education' },
  { name: 'Reading', value: 'Reading' },
  { name: 'Science', value: 'Science' },
  { name: 'Social Science', value: 'Social Science' },
  { name: 'World Language', value: 'World Language' },
  { name: 'Other', value: 'Other' },
];

export const OBS_ACTIVITY_LIST = [
  { name: 'Independent Work', value: 'Independent Work' },
  { name: 'Small Group', value: 'Small Group' },
  { name: 'Transition Time', value: 'Transition Time' },
  { name: 'Whole Group', value: 'Whole Group' },
  { name: 'Other', value: 'Other' },
];

export const CONTACT_US = 'contact-us';

export const PROGRAMS = [
  { id: 'wms', name: 'Well-Managed Schools' },
  { id: 'ai', name: 'Administrative Intervention' },
  { id: 'shss', name: 'Safe and Health Secondary Schools' },
  { id: 'scm', name: 'Specialized Classroom Management' },
  { id: 'pas', name: 'Positive Alternatives to Suspension' },
  { id: 'explit', name: 'Expedition Literacy' },
];
